import { ClientToServerEvents, ServerToClientEvents } from '@contember-cloud/studio-server'
import { Socket, io } from 'socket.io-client'

const isBrowser = typeof window !== 'undefined'
const studioServer = import.meta.env.VITE_STUDIO_SERVER

if (!studioServer) {
	throw new Error('VITE_STUDIO_SERVER is not defined')
}

export const socket: Socket<ServerToClientEvents, ClientToServerEvents> | undefined = isBrowser ? io(studioServer) : undefined

import { config } from '@/model/config'
import { socket } from '@/socket'
import { schemaSlice } from '@/state/features/schema/schemaSlice'
import { combineSlices, configureStore } from '@reduxjs/toolkit'
import { projectSlice } from './features/project/projectSlice'
import { structureSlice } from './features/structure/structureSlice'

const rootReducer = combineSlices(projectSlice, schemaSlice, structureSlice)

export const store = configureStore({
	reducer: rootReducer,
	devTools: config.mode === 'development',
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware({
			thunk: {
				extraArgument: { socket },
			},
		}),
})

export type AppStore = typeof store
export type AppDispatch = AppStore['dispatch']
export type RootState = ReturnType<AppStore['getState']>

import LimitReached from '@/pages/errors/limit-reached'
import NotAllowed from '@/pages/errors/not-allowed'
import NotFound from '@/pages/errors/not-found'
import Queue from '@/pages/errors/queue'
import React, { lazy } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

// lazy loaded to make multiple javascript bundles for each page
const Home = lazy(() => import('@/pages/home'))

const RootProjects = lazy(() => import('@/pages/projects'))
const Projects = lazy(() => import('@/pages/org/projects'))
const Edits = lazy(() => import('@/pages/org/edits'))
const Github = lazy(() => import('@/pages/org/github'))
const Deploy = lazy(() => import('@/pages/org/deploy'))

const Intent = lazy(() => import('@/pages/org/intent'))
const Roles = lazy(() => import('@/pages/org/roles'))
const Schema = lazy(() => import('@/pages/org/schema'))
const Specification = lazy(() => import('@/pages/org/specification'))
const Structure = lazy(() => import('@/pages/org/structure'))

const Account = lazy(() => import('@/pages/account/account'))
const Login = lazy(() => import('@/pages/account/login'))
const LoginReturn = lazy(() => import('@/pages/account/login-return'))
const Logout = lazy(() => import('@/pages/account/logout'))

const Cli = lazy(() => import('@/pages/cli'))
const Support = lazy(() => import('@/pages/support'))

const InternalLookup = lazy(() => import('@/pages/internal/lookup'))

export const Router = () => {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<Home />} />

				<Route path="/:org/projects" element={<Projects />} />
				<Route path="/:org/deploy" element={<Deploy />} />
				<Route path="/:org/edits" element={<Edits />} />
				<Route path="/:org/github" element={<Github />} />

				<Route path="/:org/intent" element={<Intent />} />
				<Route path="/:org/roles" element={<Roles />} />
				<Route path="/:org/schema" element={<Schema />} />
				<Route path="/:org/specification" element={<Specification />} />
				<Route path="/:org/structure" element={<Structure />} />

				<Route path="/account" element={<Account />} />
				<Route path="/login" element={<Login />} />
				<Route path="/login-return" element={<LoginReturn />} />
				<Route path="/logout" element={<Logout />} />

				<Route path="/cli" element={<Cli />} />
				<Route path="/projects" element={<RootProjects />} />
				<Route path="/support" element={<Support />} />

				<Route path="/internal/lookup" element={<InternalLookup />} />

				<Route path="/error/limit-reached" element={<LimitReached />} />
				<Route path="/error/not-allowed" element={<NotAllowed />} />
				<Route path="/error/queue" element={<Queue />} />

				<Route path="*" element={<NotFound />} />
			</Routes>
		</BrowserRouter>
	)
}

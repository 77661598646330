import { buildSchemaFromDsl } from '@contember-cloud/studio-dsl'
import { OptionsPanel, SchemaAiAssistantPrefix, SchemaSliceState } from '@contember-cloud/studio-server'
import { AnyPlugin } from '@contember/studio-plugins'
import { PayloadAction, ThunkAction, UnknownAction, createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../store'
import { resetProject } from '../project/projectSlice'

export const emptySchemaDsl = {
	past: [],
	present: '',
	future: [],
}
const initialState: SchemaSliceState = {
	dsl: emptySchemaDsl,
	showOptionsPanel: false,
}

export const schemaSlice = createSlice({
	name: 'schema',
	initialState,
	reducers: {
		resetSchemaDsl(state) {
			state.dsl = emptySchemaDsl
		},
		setSchemaDsl(state, action: PayloadAction<string>) {
			state.dsl = {
				...state.dsl,
				past: [...state.dsl.past, state.dsl.present],
				present: action.payload,
			}
		},
		addStmtToSchemaDsl(state, action: PayloadAction<string>) {
			state.dsl = {
				...state.dsl,
				past: [...state.dsl.past, state.dsl.present],
				present: state.dsl.present + `\n${action.payload}`,
			}
		},
		stepBackInSchemaDsl(state) {
			if (state.dsl.past.length === 0) return

			state.dsl = {
				...state.dsl,
				future: [state.dsl.present, ...state.dsl.future],
				present: state.dsl.past[state.dsl.past.length - 1],
				past: state.dsl.past.slice(0, state.dsl.past.length - 1),
			}
		},
		stepForwardInSchemaDsl(state) {
			if (state.dsl.future.length === 0) return

			state.dsl = {
				...state.dsl,
				past: [...state.dsl.past, state.dsl.present],
				present: state.dsl.future[0],
				future: state.dsl.future.slice(1),
			}
		},
		addStmtToSchemaDslWithoutHistory(state, action: PayloadAction<string>) {
			state.dsl = {
				...state.dsl,
				present: state.dsl.present + `\n${action.payload}`,
			}
		},
		setPendingSchemaDsl(state, action: PayloadAction<string>) {
			state.pendingDsl = action.payload
		},
		addStmtToPendingSchemaDsl(state, action: PayloadAction<string>) {
			state.pendingDsl = (state.pendingDsl ?? '') + `\n${action.payload}`
		},
		resetPendingSchemaDsl(state) {
			state.pendingDsl = undefined
		},
		removeStmtFromPendingSchemaDsl(state, action: PayloadAction<string>) {
			state.pendingDsl = state.pendingDsl?.replace(action.payload, '')
		},
		setSchema(state, action: PayloadAction<SchemaSliceState>) {
			return action.payload
		},
		setSchemaAIAssistantPrefix(state, action: PayloadAction<SchemaAiAssistantPrefix>) {
			state.aiAssistant = {
				...state.aiAssistant,
				prefix: action.payload,
			}
		},
		resetSchemaAIAssistantPrefix(state) {
			state.aiAssistant = {
				...state.aiAssistant,
				prefix: undefined,
			}
		},
		setShowOptionsPanel(state, action: PayloadAction<boolean>) {
			state.showOptionsPanel = action.payload
		},
		setOptionsPanel(state, action: PayloadAction<SchemaSliceState['optionsPanel']>) {
			state.optionsPanel = action.payload
		},
		setOptionsPanelEntity(state, action: PayloadAction<OptionsPanel>) {
			state.showOptionsPanel = true
			state.optionsPanel = {
				...state.optionsPanel,
				...action.payload,
			}
		},
		setSchemaVisualizer(state, action: PayloadAction<SchemaSliceState['schemaVisualizer']>) {
			state.schemaVisualizer = action.payload
		},
	},
	extraReducers: builder => {
		builder.addCase(resetProject, () => {
			return initialState
		})
	},
})

export const setSchemaDslAndEmit = (dsl: string): ThunkAction<void, RootState, any, UnknownAction> => {
	return (dispatch, getState, { socket }) => {
		dispatch({ type: 'schema/setSchemaDsl', payload: dsl })

		socket.emit('setSchemaDsl', { dsl, projectId: getState().project.id })
	}
}

export const addStmtToSchemaDslAndEmit = (stmt: string): ThunkAction<void, RootState, any, UnknownAction> => {
	return (dispatch, getState, { socket }) => {
		dispatch({ type: 'schema/addStmtToSchemaDsl', payload: stmt })

		socket.emit('addStmtToSchemaDsl', { stmt, projectId: getState().project.id })
	}
}

export const addStmtToSchemaDslWithoutHistoryAndEmit = (stmt: string): ThunkAction<void, RootState, any, UnknownAction> => {
	return (dispatch, getState, { socket }) => {
		dispatch({ type: 'schema/addStmtToSchemaDslWithoutHistory', payload: stmt })

		socket.emit('addStmtToSchemaDsl', { stmt, projectId: getState().project.id })
	}
}

export const {
	resetSchemaDsl,
	setSchemaDsl,
	addStmtToSchemaDsl,
	addStmtToSchemaDslWithoutHistory,

	setPendingSchemaDsl,
	addStmtToPendingSchemaDsl,
	removeStmtFromPendingSchemaDsl,
	resetPendingSchemaDsl,

	setSchemaAIAssistantPrefix,
	resetSchemaAIAssistantPrefix,
	setShowOptionsPanel,
	setOptionsPanel,
	setOptionsPanelEntity,
	setSchema,
	setSchemaVisualizer,

	stepBackInSchemaDsl,
	stepForwardInSchemaDsl,
} = schemaSlice.actions

export const selectSchema = (state: RootState) => {
	return state.schema.dsl.present ? buildSchemaFromDsl(state.schema.dsl.present, state.project.plugins as unknown as AnyPlugin[]).schema : undefined
}

export const selectSchemaState = (state: RootState) => state.schema

export default schemaSlice.reducer

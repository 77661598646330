import { ReactNode } from 'react'

export const LoginBackground = ({ className }: { className?: string }) => (
	<svg width="640" height="832" viewBox="0 0 640 832" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
		<g clipPath="url(#clip0_2724_2439)">
			<rect width="640" height="832" fill="#EEE4FF" />
			<rect x="231.017" y="1177.89" width="51.0001" height="1390" transform="rotate(-150 231.017 1177.89)" fill="#17001D" />
			<rect x="186.85" y="1152.39" width="60.0001" height="1390" transform="rotate(-150 186.85 1152.39)" fill="#38004D" />
			<rect x="134.888" y="1122.39" width="68.0001" height="1390" transform="rotate(-150 134.888 1122.39)" fill="#54017E" />
			<rect x="75.998" y="1088.39" width="77.0001" height="1390" transform="rotate(-150 75.998 1088.39)" fill="#6B03B0" />
			<rect x="9.31445" y="1049.89" width="86.0001" height="1390" transform="rotate(-150 9.31445 1049.89)" fill="#7A06E2" />
			<rect x="-65.1641" y="1006.89" width="77.0001" height="1390" transform="rotate(-150 -65.1641 1006.89)" fill="#841DFB" />
			<rect x="-130.981" y="968.888" width="68.0001" height="1390" transform="rotate(-150 -130.981 968.888)" fill="#934EFC" />
			<rect x="-189.871" y="934.888" width="60.0001" height="1390" transform="rotate(-150 -189.871 934.888)" fill="#A980FD" />
			<rect x="-241.833" y="904.888" width="51.0001" height="1390" transform="rotate(-150 -241.833 904.888)" fill="#D2C2FF" />
		</g>
		<defs>
			<clipPath id="clip0_2724_2439">
				<rect width="640" height="832" fill="white" />
			</clipPath>
		</defs>
	</svg>
)

export const LoginHeadline = ({ children }: { children?: ReactNode }) => (
	<h1 className="font-wallop font-semibold text-3xl leading-tight relative text-center">{children ?? 'AI-assisted Studio'}</h1>
)

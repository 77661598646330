import { LayoutAuth } from '@/components/layouts'
import { Button } from '@/components/ui/button'
import { cls } from '@contember-cloud/studio-server'

export default () => {
	return (
		<LayoutAuth>
			<div className="max-w-[530px]">
				<h1 className="font-wallop font-semibold text-3xl leading-tight relative mb-5">We've hit our limits</h1>
				<div className="prose">
					<p>
						We are sorry, but there is a lot of demand for our services right now. We are working hard to increase our capacity and will be back online soon.
					</p>
					<p className={'mt-8 text-lg flex justify-start'}>
						<a href={'/_/projects'}>
							<Button size="lg">Back to projects</Button>
						</a>
					</p>
				</div>
			</div>
		</LayoutAuth>
	)
}

import { LayoutAuth } from '@/components/layouts'
import { LoginHeadline } from '@/components/login'

export default () => {
	return (
		<LayoutAuth>
			<LoginHeadline />
			<p className="max-w-[530px] text-center">
				You’re not in closed private beta, please <a href="https://rik9zhzhn1g.typeform.com/to/UWv1lamK">sign up to join our waiting list</a> to be
				notified when AI Studio becomes available.
			</p>
		</LayoutAuth>
	)
}

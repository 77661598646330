import { useApi } from '@/hooks/useApi'
import { routerCatalog } from '@/model/routes-catalog'
import { selectProjectState, setProjectId, setProjectIsSaved } from '@/state/features/project/projectSlice'
import { selectSchemaState } from '@/state/features/schema/schemaSlice'
import { selectStructureState } from '@/state/features/structure/structureSlice'
import { useAppDispatch, useAppSelector } from '@/state/hooks'
import { SaveProjectRequestBody, SaveProjectResponseBody } from '@contember-cloud/studio-server'
import { useCallback, useRef } from 'react'
import { useParams } from 'react-router-dom'

export const useSaveProject = () => {
	const dispatch = useAppDispatch()
	const project = useAppSelector(selectProjectState)

	const { org } = useParams()
	const { dsl: schemaDsl } = useAppSelector(selectSchemaState)
	const { dsl: structureDsl, crudDsl: structureCrudDsl } = useAppSelector(selectStructureState)

	const structure = { dsl: structureDsl, crudDsl: structureCrudDsl }

	const mutableRefObject = useRef({
		project,
		schemaDsl: schemaDsl.present,
		structure: { dsl: structure.dsl.present, crudDsl: structure.crudDsl },
		org,
	})

	mutableRefObject.current = {
		project,
		schemaDsl: schemaDsl.present,
		structure: { dsl: structure.dsl.present, crudDsl: structure.crudDsl },
		org,
	}

	const [run, state] = useApi<SaveProjectRequestBody, SaveProjectResponseBody>(routerCatalog.apiServer.projects.save)

	return {
		run: useCallback(async () => {
			if (project.id === undefined) {
				return null
			}

			const result = await run(mutableRefObject.current)

			if (result.data.ok && result.data.result.id) {
				dispatch(setProjectId(result.data.result.id))
				dispatch(setProjectIsSaved(true))
				return result.data.result.id
			}

			return null
		}, [dispatch, project.id, run]),
		state,
	}
}

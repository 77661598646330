import {
	AlertDialog,
	AlertDialogAction,
	AlertDialogContent,
	AlertDialogDescription,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogTitle,
} from '@/components/ui/alert-dialog'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { useSaveProject } from '@/hooks/project/useSaveProject'
import { setProjectOrganizationId } from '@/state/features/project/projectSlice'
import { useAppDispatch } from '@/state/hooks'
import { MeResponseOrganization } from '@contember-cloud/studio-server'
import { TriangleAlertIcon } from 'lucide-react'
import { useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

export type OrganizationProps = {
	organizations: MeResponseOrganization[]
}

export const OrganizationAlert = ({ organizations }: OrganizationProps) => {
	const dispatch = useAppDispatch()

	const { org } = useParams()
	const { run: saveProject } = useSaveProject()

	const [value, setValue] = useState<string | undefined>(organizations.find(o => o.slug === org)?.id)

	useEffect(() => {
		if (value) {
			dispatch(setProjectOrganizationId(value))
		}
	}, [value, dispatch])

	return (
		<AlertDialog defaultOpen>
			<AlertDialogContent>
				<AlertDialogHeader>
					<AlertDialogTitle>
						<div className={'flex gap-2 items-center'}>
							<TriangleAlertIcon className={'text-orange-600'} /> This project doesn't belong to any organization
						</div>
					</AlertDialogTitle>
					<AlertDialogDescription>
						<p className={'my-2'}>Please select an organization to continue.</p>
						<Select value={value} onValueChange={setValue}>
							<SelectTrigger>
								<SelectValue placeholder={'Select organization ...'} />
							</SelectTrigger>
							<SelectContent>
								{organizations.map(org => (
									<SelectItem value={org.id} key={org.id}>
										{org.name}
									</SelectItem>
								))}
							</SelectContent>
						</Select>
					</AlertDialogDescription>
				</AlertDialogHeader>
				<AlertDialogFooter>
					<AlertDialogAction
						disabled={!value}
						onClick={async () => {
							await saveProject()
						}}
					>
						Continue
					</AlertDialogAction>
				</AlertDialogFooter>
			</AlertDialogContent>
		</AlertDialog>
	)
}

export const OrganizationSelect = ({ organizations }: OrganizationProps) => {
	const navigate = useNavigate()
	const { org } = useParams()

	const [value, setValue] = useState<string | undefined>(org)

	const handleValueChange = (value: string) => {
		setValue(value)
		localStorage.setItem('org_slug', value)
		window.location.href = `/${value}/projects`
	}

	return (
		<Select value={value} onValueChange={handleValueChange}>
			<SelectTrigger className="w-auto bg-transparent border-neutral-500 gap-2">
				<SelectValue placeholder="Select organization ..." />
			</SelectTrigger>
			<SelectContent>
				{organizations?.map(org => (
					<SelectItem key={org.slug} value={org.slug}>
						{org.name}
					</SelectItem>
				))}
			</SelectContent>
		</Select>
	)
}

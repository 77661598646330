import { LayoutAuth } from '@/components/layouts'
import { Button } from '@/components/ui/button'
import { cls } from '@contember-cloud/studio-server'

export default () => {
	return (
		<LayoutAuth>
			<div className="max-w-[530px]">
				<h1 className="font-wallop font-semibold text-3xl leading-tight relative mb-5">Monthly project limit reached</h1>
				<div className="prose">
					<p>
						It looks like you’ve hit the project limit for your current tier, and we’re thrilled to see how actively you’ve been using AI Studio. We’d
						love to schedule a quick call to better understand your usage, and from there, we’ll be happy to increase your limits so you can continue
						moving forward without any interruptions.
					</p>
					<p className={'mt-8 text-lg flex justify-start'}>
						<a href={'https://selfcare.eu.contember.cloud/org/_/studio-subscription'}>
							<Button size="lg">Schedule a call</Button>
						</a>
					</p>
				</div>
			</div>
		</LayoutAuth>
	)
}

import './styles/global.css'
import './instrumentation'

import { Toaster } from '@/components/ui/sonner'
import { Identity, IdentityContext } from '@/model/context/IdentityContext'
import { Router } from '@/router'
import { store } from '@/state/store'
import { useState } from 'react'
import React, { useEffect } from 'react'
import ReactDOM from 'react-dom/client'
import { HelmetProvider } from 'react-helmet-async'
import { hotjar } from 'react-hotjar'
import { Provider } from 'react-redux'
import { IntercomProvider } from 'react-use-intercom'

const INTERCOM_APP_ID = 'zlm5my4a'

const root = document.getElementById('root')!

const App = () => {
	const [identity, setIdentity] = useState<Identity | null>(null)

	useEffect(() => hotjar.initialize(3392123, 6))

	return (
		<IntercomProvider appId={INTERCOM_APP_ID}>
			<HelmetProvider>
				<Provider store={store}>
					<IdentityContext.Provider value={{ identity, setIdentity }}>
						<Router />
					</IdentityContext.Provider>
					<Toaster />
				</Provider>
			</HelmetProvider>
		</IntercomProvider>
	)
}

ReactDOM.createRoot(root).render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
)

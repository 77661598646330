import { MeResponseOrganization, Person } from '@contember-cloud/studio-server'
import { createContext, useContext } from 'react'

export interface Identity {
	person: Person
	personAdmin: boolean
	organizations: MeResponseOrganization[]
}

interface IdentityContextType {
	identity: Identity | null
	setIdentity: (identity: Identity | null) => void
}

export const IdentityContext = createContext<IdentityContextType | undefined>(undefined)

export const useIdentity = () => {
	const context = useContext(IdentityContext)
	if (!context) {
		throw new Error('useIdentity must be used within an IdentityProvider')
	}

	return context
}

import * as Sentry from '@sentry/react'
import { useCallback, useEffect, useRef, useState } from 'react'

export type FetchState<T = unknown> = { type: 'empty' } | { type: 'pending' } | { type: 'failed'; message: string } | { type: 'success'; data: T }
export type UseApiCallback<Req = unknown, Res = unknown> = (data: Req) => Promise<{ data: Res; response: Response }>
export type UseApi<Req = unknown, Res = unknown> = [fetch: UseApiCallback<Req, Res>, state: FetchState<Res>]

export type UseApiMethod = 'GET' | 'POST' | 'PUT' | 'DELETE'

export type UseApiOptions = {
	method?: 'GET' | 'POST' | 'PUT' | 'DELETE'
	headers?: Record<string, string>
	timeout?: number
}

export const useApi = <Req = unknown, Res = unknown>(url: string, options: UseApiOptions = {}): UseApi<Req, Res> => {
	const [state, setState] = useState<FetchState<Res>>({ type: 'empty' })

	const fetchCallback = useCallback(
		async (reqData: Req): Promise<{ data: Res; response: Response }> => {
			setState({ type: 'pending' })

			const method = options.method ?? 'POST'
			const headers = options.headers ?? {}
			const timeout = options.timeout ?? 30000

			if (reqData && method !== 'GET') {
				headers['Content-Type'] = 'application/json'
			}

			try {
				const res = await Promise.race([
					fetch(url, {
						method: method,
						body: method !== 'GET' ? JSON.stringify(reqData) : undefined,
						headers: headers,
						credentials: 'include',
					}),

					new Promise<never>((_, reject) => setTimeout(() => reject(new Error('Request timeout')), timeout)),
				])

				if (!res.ok) {
					const errorData = await res.json()
					Sentry.captureException(errorData.error ?? res)
					setState({ type: 'failed', message: errorData.error ?? res.statusText })

					return { response: res, data: { ...errorData, ok: false } as Res }
				}

				if (!res.body) {
					throw new Error('No response body')
				}

				const contentType = res.headers.get('Content-Type')
				if (contentType?.includes('application/json')) {
					const data = (await res.json()) as Res
					setState({ type: 'success', data })
					return { response: res, data }
				}

				const reader = res.body.getReader()
				const decoder = new TextDecoder('utf-8')
				let result = ''
				let partialData: Partial<Res> = {}

				while (true) {
					const { done, value } = await reader.read()
					if (done) break
					result += decoder.decode(value, { stream: true })
					const lines = result.split('\n\n')
					for (const line of lines) {
						if (line.trim()) {
							let message: any
							try {
								message = JSON.parse(line.replace('data: ', ''))
							} catch (e) {
								// If parsing fails, treat it as plain text
								message = { data: line.replace('data: ', '') }
							}

							if (message.ok === false) {
								setState({ type: 'failed', message: message.error })
								return { response: res, data: message as Res }
							}

							partialData = { ...partialData, ...message }
							setState({ type: 'success', data: partialData as Res })
						}
					}
				}

				return { response: res, data: partialData as Res }
			} catch (e) {
				const error = e as Error
				setState({ type: 'failed', message: error.message })
				throw error
			}
		},
		[url, options.headers, options.method, options.timeout],
	)

	return [fetchCallback, state]
}
